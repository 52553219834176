import _evEmitter from "ev-emitter";
import _getSize from "get-size";
import _fizzyUiUtils from "fizzy-ui-utils";
import _cell from "./cell";
import _slide from "./slide";
import _animate from "./animate";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// Flickity main

/* eslint-disable max-params */
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _evEmitter, _getSize, _fizzyUiUtils, _cell, _slide, _animate);
  } else {
    // browser global
    var _Flickity = window.Flickity;
    window.Flickity = factory(window, window.EvEmitter, window.getSize, window.fizzyUIUtils, _Flickity.Cell, _Flickity.Slide, _Flickity.animatePrototype);
  }
})(window, function factory(window, EvEmitter, getSize, utils, Cell, Slide, animatePrototype) {
  /* eslint-enable max-params */
  'use strict'; // vars

  var jQuery = window.jQuery;
  var getComputedStyle = window.getComputedStyle;
  var console = window.console;

  function moveElements(elems, toElem) {
    elems = utils.makeArray(elems);

    while (elems.length) {
      toElem.appendChild(elems.shift());
    }
  } // -------------------------- Flickity -------------------------- //
  // globally unique identifiers


  var GUID = 0; // internal store of all Flickity intances

  var instances = {};

  function Flickity(element, options) {
    var queryElement = utils.getQueryElement(element);

    if (!queryElement) {
      if (console) {
        console.error("Bad element for Flickity: " + (queryElement || element));
      }

      return;
    }

    (this || _global).element = queryElement; // do not initialize twice on same element

    if ((this || _global).element.flickityGUID) {
      var instance = instances[(this || _global).element.flickityGUID];
      if (instance) instance.option(options);
      return instance;
    } // add jQuery


    if (jQuery) {
      (this || _global).$element = jQuery((this || _global).element);
    } // options


    (this || _global).options = utils.extend({}, (this || _global).constructor.defaults);
    this.option(options); // kick things off

    this._create();
  }

  Flickity.defaults = {
    accessibility: true,
    // adaptiveHeight: false,
    cellAlign: "center",
    // cellSelector: undefined,
    // contain: false,
    freeScrollFriction: 0.075,
    // friction when free-scrolling
    friction: 0.28,
    // friction when selecting
    namespaceJQueryEvents: true,
    // initialIndex: 0,
    percentPosition: true,
    resize: true,
    selectedAttraction: 0.025,
    setGallerySize: true // watchCSS: false,
    // wrapAround: false

  }; // hash of methods triggered on _create()

  Flickity.createMethods = [];
  var proto = Flickity.prototype; // inherit EventEmitter

  utils.extend(proto, EvEmitter.prototype);

  proto._create = function () {
    // add id for Flickity.data
    var id = (this || _global).guid = ++GUID;
    (this || _global).element.flickityGUID = id; // expando

    instances[id] = this || _global; // associate via id
    // initial properties

    (this || _global).selectedIndex = 0; // how many frames slider has been in same position

    (this || _global).restingFrames = 0; // initial physics properties

    (this || _global).x = 0;
    (this || _global).velocity = 0;
    (this || _global).originSide = (this || _global).options.rightToLeft ? "right" : "left"; // create viewport & slider

    (this || _global).viewport = document.createElement("div");
    (this || _global).viewport.className = "flickity-viewport";

    this._createSlider();

    if ((this || _global).options.resize || (this || _global).options.watchCSS) {
      window.addEventListener("resize", this || _global);
    } // add listeners from on option


    for (var eventName in (this || _global).options.on) {
      var listener = (this || _global).options.on[eventName];
      this.on(eventName, listener);
    }

    Flickity.createMethods.forEach(function (method) {
      this[method]();
    }, this || _global);

    if ((this || _global).options.watchCSS) {
      this.watchCSS();
    } else {
      this.activate();
    }
  };
  /**
   * set options
   * @param {Object} opts - options to extend
   */


  proto.option = function (opts) {
    utils.extend((this || _global).options, opts);
  };

  proto.activate = function () {
    if ((this || _global).isActive) {
      return;
    }

    (this || _global).isActive = true;

    (this || _global).element.classList.add("flickity-enabled");

    if ((this || _global).options.rightToLeft) {
      (this || _global).element.classList.add("flickity-rtl");
    }

    this.getSize(); // move initial cell elements so they can be loaded as cells

    var cellElems = this._filterFindCellElements((this || _global).element.children);

    moveElements(cellElems, (this || _global).slider);

    (this || _global).viewport.appendChild((this || _global).slider);

    (this || _global).element.appendChild((this || _global).viewport); // get cells from children


    this.reloadCells();

    if ((this || _global).options.accessibility) {
      // allow element to focusable
      (this || _global).element.tabIndex = 0; // listen for key presses

      (this || _global).element.addEventListener("keydown", this || _global);
    }

    this.emitEvent("activate");
    this.selectInitialIndex(); // flag for initial activation, for using initialIndex

    (this || _global).isInitActivated = true; // ready event. #493

    this.dispatchEvent("ready");
  }; // slider positions the cells


  proto._createSlider = function () {
    // slider element does all the positioning
    var slider = document.createElement("div");
    slider.className = "flickity-slider";
    slider.style[(this || _global).originSide] = 0;
    (this || _global).slider = slider;
  };

  proto._filterFindCellElements = function (elems) {
    return utils.filterFindElements(elems, (this || _global).options.cellSelector);
  }; // goes through all children


  proto.reloadCells = function () {
    // collection of item elements
    (this || _global).cells = this._makeCells((this || _global).slider.children);
    this.positionCells();

    this._getWrapShiftCells();

    this.setGallerySize();
  };
  /**
   * turn elements into Flickity.Cells
   * @param {[Array, NodeList, HTMLElement]} elems - elements to make into cells
   * @returns {Array} items - collection of new Flickity Cells
   */


  proto._makeCells = function (elems) {
    var cellElems = this._filterFindCellElements(elems); // create new Flickity for collection


    var cells = cellElems.map(function (cellElem) {
      return new Cell(cellElem, this || _global);
    }, this || _global);
    return cells;
  };

  proto.getLastCell = function () {
    return (this || _global).cells[(this || _global).cells.length - 1];
  };

  proto.getLastSlide = function () {
    return (this || _global).slides[(this || _global).slides.length - 1];
  }; // positions all cells


  proto.positionCells = function () {
    // size all cells
    this._sizeCells((this || _global).cells); // position all cells


    this._positionCells(0);
  };
  /**
   * position certain cells
   * @param {Integer} index - which cell to start with
   */


  proto._positionCells = function (index) {
    index = index || 0; // also measure maxCellHeight
    // start 0 if positioning all cells

    (this || _global).maxCellHeight = index ? (this || _global).maxCellHeight || 0 : 0;
    var cellX = 0; // get cellX

    if (index > 0) {
      var startCell = (this || _global).cells[index - 1];
      cellX = startCell.x + startCell.size.outerWidth;
    }

    var len = (this || _global).cells.length;

    for (var i = index; i < len; i++) {
      var cell = (this || _global).cells[i];
      cell.setPosition(cellX);
      cellX += cell.size.outerWidth;
      (this || _global).maxCellHeight = Math.max(cell.size.outerHeight, (this || _global).maxCellHeight);
    } // keep track of cellX for wrap-around


    (this || _global).slideableWidth = cellX; // slides

    this.updateSlides(); // contain slides target

    this._containSlides(); // update slidesWidth


    (this || _global).slidesWidth = len ? this.getLastSlide().target - (this || _global).slides[0].target : 0;
  };
  /**
   * cell.getSize() on multiple cells
   * @param {Array} cells - cells to size
   */


  proto._sizeCells = function (cells) {
    cells.forEach(function (cell) {
      cell.getSize();
    });
  }; // --------------------------  -------------------------- //


  proto.updateSlides = function () {
    (this || _global).slides = [];

    if (!(this || _global).cells.length) {
      return;
    }

    var slide = new Slide(this || _global);

    (this || _global).slides.push(slide);

    var isOriginLeft = (this || _global).originSide == "left";
    var nextMargin = isOriginLeft ? "marginRight" : "marginLeft";

    var canCellFit = this._getCanCellFit();

    (this || _global).cells.forEach(function (cell, i) {
      // just add cell if first cell in slide
      if (!slide.cells.length) {
        slide.addCell(cell);
        return;
      }

      var slideWidth = slide.outerWidth - slide.firstMargin + (cell.size.outerWidth - cell.size[nextMargin]);

      if (canCellFit.call(this || _global, i, slideWidth)) {
        slide.addCell(cell);
      } else {
        // doesn't fit, new slide
        slide.updateTarget();
        slide = new Slide(this || _global);

        (this || _global).slides.push(slide);

        slide.addCell(cell);
      }
    }, this || _global); // last slide


    slide.updateTarget(); // update .selectedSlide

    this.updateSelectedSlide();
  };

  proto._getCanCellFit = function () {
    var groupCells = (this || _global).options.groupCells;

    if (!groupCells) {
      return function () {
        return false;
      };
    } else if (typeof groupCells == "number") {
      // group by number. 3 -> [0,1,2], [3,4,5], ...
      var number = parseInt(groupCells, 10);
      return function (i) {
        return i % number !== 0;
      };
    } // default, group by width of slide
    // parse '75%


    var percentMatch = typeof groupCells == "string" && groupCells.match(/^(\d+)%$/);
    var percent = percentMatch ? parseInt(percentMatch[1], 10) / 100 : 1;
    return function (i, slideWidth) {
      /* eslint-disable-next-line no-invalid-this */
      return slideWidth <= ((this || _global).size.innerWidth + 1) * percent;
    };
  }; // alias _init for jQuery plugin .flickity()


  proto._init = proto.reposition = function () {
    this.positionCells();
    this.positionSliderAtSelected();
  };

  proto.getSize = function () {
    (this || _global).size = getSize((this || _global).element);
    this.setCellAlign();
    (this || _global).cursorPosition = (this || _global).size.innerWidth * (this || _global).cellAlign;
  };

  var cellAlignShorthands = {
    // cell align, then based on origin side
    center: {
      left: 0.5,
      right: 0.5
    },
    left: {
      left: 0,
      right: 1
    },
    right: {
      right: 0,
      left: 1
    }
  };

  proto.setCellAlign = function () {
    var shorthand = cellAlignShorthands[(this || _global).options.cellAlign];
    (this || _global).cellAlign = shorthand ? shorthand[(this || _global).originSide] : (this || _global).options.cellAlign;
  };

  proto.setGallerySize = function () {
    if ((this || _global).options.setGallerySize) {
      var height = (this || _global).options.adaptiveHeight && (this || _global).selectedSlide ? (this || _global).selectedSlide.height : (this || _global).maxCellHeight;
      (this || _global).viewport.style.height = height + "px";
    }
  };

  proto._getWrapShiftCells = function () {
    // only for wrap-around
    if (!(this || _global).options.wrapAround) {
      return;
    } // unshift previous cells


    this._unshiftCells((this || _global).beforeShiftCells);

    this._unshiftCells((this || _global).afterShiftCells); // get before cells
    // initial gap


    var gapX = (this || _global).cursorPosition;
    var cellIndex = (this || _global).cells.length - 1;
    (this || _global).beforeShiftCells = this._getGapCells(gapX, cellIndex, -1); // get after cells
    // ending gap between last cell and end of gallery viewport

    gapX = (this || _global).size.innerWidth - (this || _global).cursorPosition; // start cloning at first cell, working forwards

    (this || _global).afterShiftCells = this._getGapCells(gapX, 0, 1);
  };

  proto._getGapCells = function (gapX, cellIndex, increment) {
    // keep adding cells until the cover the initial gap
    var cells = [];

    while (gapX > 0) {
      var cell = (this || _global).cells[cellIndex];

      if (!cell) {
        break;
      }

      cells.push(cell);
      cellIndex += increment;
      gapX -= cell.size.outerWidth;
    }

    return cells;
  }; // ----- contain ----- //
  // contain cell targets so no excess sliding


  proto._containSlides = function () {
    if (!(this || _global).options.contain || (this || _global).options.wrapAround || !(this || _global).cells.length) {
      return;
    }

    var isRightToLeft = (this || _global).options.rightToLeft;
    var beginMargin = isRightToLeft ? "marginRight" : "marginLeft";
    var endMargin = isRightToLeft ? "marginLeft" : "marginRight";
    var contentWidth = (this || _global).slideableWidth - this.getLastCell().size[endMargin]; // content is less than gallery size

    var isContentSmaller = contentWidth < (this || _global).size.innerWidth; // bounds

    var beginBound = (this || _global).cursorPosition + (this || _global).cells[0].size[beginMargin];
    var endBound = contentWidth - (this || _global).size.innerWidth * (1 - (this || _global).cellAlign); // contain each cell target

    (this || _global).slides.forEach(function (slide) {
      if (isContentSmaller) {
        // all cells fit inside gallery
        slide.target = contentWidth * (this || _global).cellAlign;
      } else {
        // contain to bounds
        slide.target = Math.max(slide.target, beginBound);
        slide.target = Math.min(slide.target, endBound);
      }
    }, this || _global);
  }; // -----  ----- //

  /**
   * emits events via eventEmitter and jQuery events
   * @param {String} type - name of event
   * @param {Event} event - original event
   * @param {Array} args - extra arguments
   */


  proto.dispatchEvent = function (type, event, args) {
    var emitArgs = event ? [event].concat(args) : args;
    this.emitEvent(type, emitArgs);

    if (jQuery && (this || _global).$element) {
      // default trigger with type if no event
      type += (this || _global).options.namespaceJQueryEvents ? ".flickity" : "";
      var $event = type;

      if (event) {
        // create jQuery event
        var jQEvent = new jQuery.Event(event);
        jQEvent.type = type;
        $event = jQEvent;
      }

      (this || _global).$element.trigger($event, args);
    }
  }; // -------------------------- select -------------------------- //

  /**
   * @param {Integer} index - index of the slide
   * @param {Boolean} isWrap - will wrap-around to last/first if at the end
   * @param {Boolean} isInstant - will immediately set position at selected cell
   */


  proto.select = function (index, isWrap, isInstant) {
    if (!(this || _global).isActive) {
      return;
    }

    index = parseInt(index, 10);

    this._wrapSelect(index);

    if ((this || _global).options.wrapAround || isWrap) {
      index = utils.modulo(index, (this || _global).slides.length);
    } // bail if invalid index


    if (!(this || _global).slides[index]) {
      return;
    }

    var prevIndex = (this || _global).selectedIndex;
    (this || _global).selectedIndex = index;
    this.updateSelectedSlide();

    if (isInstant) {
      this.positionSliderAtSelected();
    } else {
      this.startAnimation();
    }

    if ((this || _global).options.adaptiveHeight) {
      this.setGallerySize();
    } // events


    this.dispatchEvent("select", null, [index]); // change event if new index

    if (index != prevIndex) {
      this.dispatchEvent("change", null, [index]);
    } // old v1 event name, remove in v3


    this.dispatchEvent("cellSelect");
  }; // wraps position for wrapAround, to move to closest slide. #113


  proto._wrapSelect = function (index) {
    var len = (this || _global).slides.length;
    var isWrapping = (this || _global).options.wrapAround && len > 1;

    if (!isWrapping) {
      return index;
    }

    var wrapIndex = utils.modulo(index, len); // go to shortest

    var delta = Math.abs(wrapIndex - (this || _global).selectedIndex);
    var backWrapDelta = Math.abs(wrapIndex + len - (this || _global).selectedIndex);
    var forewardWrapDelta = Math.abs(wrapIndex - len - (this || _global).selectedIndex);

    if (!(this || _global).isDragSelect && backWrapDelta < delta) {
      index += len;
    } else if (!(this || _global).isDragSelect && forewardWrapDelta < delta) {
      index -= len;
    } // wrap position so slider is within normal area


    if (index < 0) {
      (this || _global).x -= (this || _global).slideableWidth;
    } else if (index >= len) {
      (this || _global).x += (this || _global).slideableWidth;
    }
  };

  proto.previous = function (isWrap, isInstant) {
    this.select((this || _global).selectedIndex - 1, isWrap, isInstant);
  };

  proto.next = function (isWrap, isInstant) {
    this.select((this || _global).selectedIndex + 1, isWrap, isInstant);
  };

  proto.updateSelectedSlide = function () {
    var slide = (this || _global).slides[(this || _global).selectedIndex]; // selectedIndex could be outside of slides, if triggered before resize()

    if (!slide) {
      return;
    } // unselect previous selected slide


    this.unselectSelectedSlide(); // update new selected slide

    (this || _global).selectedSlide = slide;
    slide.select();
    (this || _global).selectedCells = slide.cells;
    (this || _global).selectedElements = slide.getCellElements(); // HACK: selectedCell & selectedElement is first cell in slide, backwards compatibility
    // Remove in v3?

    (this || _global).selectedCell = slide.cells[0];
    (this || _global).selectedElement = (this || _global).selectedElements[0];
  };

  proto.unselectSelectedSlide = function () {
    if ((this || _global).selectedSlide) {
      (this || _global).selectedSlide.unselect();
    }
  };

  proto.selectInitialIndex = function () {
    var initialIndex = (this || _global).options.initialIndex; // already activated, select previous selectedIndex

    if ((this || _global).isInitActivated) {
      this.select((this || _global).selectedIndex, false, true);
      return;
    } // select with selector string


    if (initialIndex && typeof initialIndex == "string") {
      var cell = this.queryCell(initialIndex);

      if (cell) {
        this.selectCell(initialIndex, false, true);
        return;
      }
    }

    var index = 0; // select with number

    if (initialIndex && (this || _global).slides[initialIndex]) {
      index = initialIndex;
    } // select instantly


    this.select(index, false, true);
  };
  /**
   * select slide from number or cell element
   * @param {[Element, Number]} value - zero-based index or element to select
   * @param {Boolean} isWrap - enables wrapping around for extra index
   * @param {Boolean} isInstant - disables slide animation
   */


  proto.selectCell = function (value, isWrap, isInstant) {
    // get cell
    var cell = this.queryCell(value);

    if (!cell) {
      return;
    }

    var index = this.getCellSlideIndex(cell);
    this.select(index, isWrap, isInstant);
  };

  proto.getCellSlideIndex = function (cell) {
    // get index of slides that has cell
    for (var i = 0; i < (this || _global).slides.length; i++) {
      var slide = (this || _global).slides[i];
      var index = slide.cells.indexOf(cell);

      if (index != -1) {
        return i;
      }
    }
  }; // -------------------------- get cells -------------------------- //

  /**
   * get Flickity.Cell, given an Element
   * @param {Element} elem - matching cell element
   * @returns {Flickity.Cell} cell - matching cell
   */


  proto.getCell = function (elem) {
    // loop through cells to get the one that matches
    for (var i = 0; i < (this || _global).cells.length; i++) {
      var cell = (this || _global).cells[i];

      if (cell.element == elem) {
        return cell;
      }
    }
  };
  /**
   * get collection of Flickity.Cells, given Elements
   * @param {[Element, Array, NodeList]} elems - multiple elements
   * @returns {Array} cells - Flickity.Cells
   */


  proto.getCells = function (elems) {
    elems = utils.makeArray(elems);
    var cells = [];
    elems.forEach(function (elem) {
      var cell = this.getCell(elem);

      if (cell) {
        cells.push(cell);
      }
    }, this || _global);
    return cells;
  };
  /**
   * get cell elements
   * @returns {Array} cellElems
   */


  proto.getCellElements = function () {
    return (this || _global).cells.map(function (cell) {
      return cell.element;
    });
  };
  /**
   * get parent cell from an element
   * @param {Element} elem - child element
   * @returns {Flickit.Cell} cell - parent cell
   */


  proto.getParentCell = function (elem) {
    // first check if elem is cell
    var cell = this.getCell(elem);

    if (cell) {
      return cell;
    } // try to get parent cell elem


    elem = utils.getParent(elem, ".flickity-slider > *");
    return this.getCell(elem);
  };
  /**
   * get cells adjacent to a slide
   * @param {Integer} adjCount - number of adjacent slides
   * @param {Integer} index - index of slide to start
   * @returns {Array} cells - array of Flickity.Cells
   */


  proto.getAdjacentCellElements = function (adjCount, index) {
    if (!adjCount) {
      return (this || _global).selectedSlide.getCellElements();
    }

    index = index === undefined ? (this || _global).selectedIndex : index;
    var len = (this || _global).slides.length;

    if (1 + adjCount * 2 >= len) {
      return this.getCellElements();
    }

    var cellElems = [];

    for (var i = index - adjCount; i <= index + adjCount; i++) {
      var slideIndex = (this || _global).options.wrapAround ? utils.modulo(i, len) : i;
      var slide = (this || _global).slides[slideIndex];

      if (slide) {
        cellElems = cellElems.concat(slide.getCellElements());
      }
    }

    return cellElems;
  };
  /**
   * select slide from number or cell element
   * @param {[Element, String, Number]} selector - element, selector string, or index
   * @returns {Flickity.Cell} - matching cell
   */


  proto.queryCell = function (selector) {
    if (typeof selector == "number") {
      // use number as index
      return (this || _global).cells[selector];
    }

    if (typeof selector == "string") {
      // do not select invalid selectors from hash: #123, #/. #791
      if (selector.match(/^[#.]?[\d/]/)) {
        return;
      } // use string as selector, get element


      selector = (this || _global).element.querySelector(selector);
    } // get cell from element


    return this.getCell(selector);
  }; // -------------------------- events -------------------------- //


  proto.uiChange = function () {
    this.emitEvent("uiChange");
  }; // keep focus on element when child UI elements are clicked


  proto.childUIPointerDown = function (event) {
    // HACK iOS does not allow touch events to bubble up?!
    if (event.type != "touchstart") {
      event.preventDefault();
    }

    this.focus();
  }; // ----- resize ----- //


  proto.onresize = function () {
    this.watchCSS();
    this.resize();
  };

  utils.debounceMethod(Flickity, "onresize", 150);

  proto.resize = function () {
    if (!(this || _global).isActive) {
      return;
    }

    this.getSize(); // wrap values

    if ((this || _global).options.wrapAround) {
      (this || _global).x = utils.modulo((this || _global).x, (this || _global).slideableWidth);
    }

    this.positionCells();

    this._getWrapShiftCells();

    this.setGallerySize();
    this.emitEvent("resize"); // update selected index for group slides, instant
    // TODO: position can be lost between groups of various numbers

    var selectedElement = (this || _global).selectedElements && (this || _global).selectedElements[0];
    this.selectCell(selectedElement, false, true);
  }; // watches the :after property, activates/deactivates


  proto.watchCSS = function () {
    var watchOption = (this || _global).options.watchCSS;

    if (!watchOption) {
      return;
    }

    var afterContent = getComputedStyle((this || _global).element, ":after").content; // activate if :after { content: 'flickity' }

    if (afterContent.indexOf("flickity") != -1) {
      this.activate();
    } else {
      this.deactivate();
    }
  }; // ----- keydown ----- //
  // go previous/next if left/right keys pressed


  proto.onkeydown = function (event) {
    // only work if element is in focus
    var isNotFocused = document.activeElement && document.activeElement != (this || _global).element;

    if (!(this || _global).options.accessibility || isNotFocused) {
      return;
    }

    var handler = Flickity.keyboardHandlers[event.keyCode];

    if (handler) {
      handler.call(this || _global);
    }
  };

  Flickity.keyboardHandlers = {
    // left arrow
    37: function () {
      var leftMethod = (this || _global).options.rightToLeft ? "next" : "previous";
      this.uiChange();
      this[leftMethod]();
    },
    // right arrow
    39: function () {
      var rightMethod = (this || _global).options.rightToLeft ? "previous" : "next";
      this.uiChange();
      this[rightMethod]();
    }
  }; // ----- focus ----- //

  proto.focus = function () {
    // TODO remove scrollTo once focus options gets more support
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/focus ...
    //    #Browser_compatibility
    var prevScrollY = window.pageYOffset;

    (this || _global).element.focus({
      preventScroll: true
    }); // hack to fix scroll jump after focus, #76


    if (window.pageYOffset != prevScrollY) {
      window.scrollTo(window.pageXOffset, prevScrollY);
    }
  }; // -------------------------- destroy -------------------------- //
  // deactivate all Flickity functionality, but keep stuff available


  proto.deactivate = function () {
    if (!(this || _global).isActive) {
      return;
    }

    (this || _global).element.classList.remove("flickity-enabled");

    (this || _global).element.classList.remove("flickity-rtl");

    this.unselectSelectedSlide(); // destroy cells

    (this || _global).cells.forEach(function (cell) {
      cell.destroy();
    });

    (this || _global).element.removeChild((this || _global).viewport); // move child elements back into element


    moveElements((this || _global).slider.children, (this || _global).element);

    if ((this || _global).options.accessibility) {
      (this || _global).element.removeAttribute("tabIndex");

      (this || _global).element.removeEventListener("keydown", this || _global);
    } // set flags


    (this || _global).isActive = false;
    this.emitEvent("deactivate");
  };

  proto.destroy = function () {
    this.deactivate();
    window.removeEventListener("resize", this || _global);
    this.allOff();
    this.emitEvent("destroy");

    if (jQuery && (this || _global).$element) {
      jQuery.removeData((this || _global).element, "flickity");
    }

    delete (this || _global).element.flickityGUID;
    delete instances[(this || _global).guid];
  }; // -------------------------- prototype -------------------------- //


  utils.extend(proto, animatePrototype); // -------------------------- extras -------------------------- //

  /**
   * get Flickity instance from element
   * @param {[Element, String]} elem - element or selector string
   * @returns {Flickity} - Flickity instance
   */

  Flickity.data = function (elem) {
    elem = utils.getQueryElement(elem);
    var id = elem && elem.flickityGUID;
    return id && instances[id];
  };

  utils.htmlInit(Flickity, "flickity");

  if (jQuery && jQuery.bridget) {
    jQuery.bridget("flickity", Flickity);
  } // set internal jQuery, for Webpack + jQuery v3, #478


  Flickity.setJQuery = function (jq) {
    jQuery = jq;
  };

  Flickity.Cell = Cell;
  Flickity.Slide = Slide;
  return Flickity;
});

export default exports;