import _flickity from "./flickity";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// add, remove cell
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _flickity, _fizzyUiUtils);
  } else {
    // browser global
    factory(window, window.Flickity, window.fizzyUIUtils);
  }
})(window, function factory(window, Flickity, utils) {
  'use strict'; // append cells to a document fragment

  function getCellsFragment(cells) {
    var fragment = document.createDocumentFragment();
    cells.forEach(function (cell) {
      fragment.appendChild(cell.element);
    });
    return fragment;
  } // -------------------------- add/remove cell prototype -------------------------- //


  var proto = Flickity.prototype;
  /**
   * Insert, prepend, or append cells
   * @param {[Element, Array, NodeList]} elems - Elements to insert
   * @param {Integer} index - Zero-based number to insert
   */

  proto.insert = function (elems, index) {
    var cells = this._makeCells(elems);

    if (!cells || !cells.length) {
      return;
    }

    var len = (this || _global).cells.length; // default to append

    index = index === undefined ? len : index; // add cells with document fragment

    var fragment = getCellsFragment(cells); // append to slider

    var isAppend = index == len;

    if (isAppend) {
      (this || _global).slider.appendChild(fragment);
    } else {
      var insertCellElement = (this || _global).cells[index].element;

      (this || _global).slider.insertBefore(fragment, insertCellElement);
    } // add to this.cells


    if (index === 0) {
      // prepend, add to start
      (this || _global).cells = cells.concat((this || _global).cells);
    } else if (isAppend) {
      // append, add to end
      (this || _global).cells = (this || _global).cells.concat(cells);
    } else {
      // insert in this.cells
      var endCells = (this || _global).cells.splice(index, len - index);

      (this || _global).cells = (this || _global).cells.concat(cells).concat(endCells);
    }

    this._sizeCells(cells);

    this.cellChange(index, true);
  };

  proto.append = function (elems) {
    this.insert(elems, (this || _global).cells.length);
  };

  proto.prepend = function (elems) {
    this.insert(elems, 0);
  };
  /**
   * Remove cells
   * @param {[Element, Array, NodeList]} elems - ELements to remove
   */


  proto.remove = function (elems) {
    var cells = this.getCells(elems);

    if (!cells || !cells.length) {
      return;
    }

    var minCellIndex = (this || _global).cells.length - 1; // remove cells from collection & DOM

    cells.forEach(function (cell) {
      cell.remove();

      var index = (this || _global).cells.indexOf(cell);

      minCellIndex = Math.min(index, minCellIndex);
      utils.removeFrom((this || _global).cells, cell);
    }, this || _global);
    this.cellChange(minCellIndex, true);
  };
  /**
   * logic to be run after a cell's size changes
   * @param {Element} elem - cell's element
   */


  proto.cellSizeChange = function (elem) {
    var cell = this.getCell(elem);

    if (!cell) {
      return;
    }

    cell.getSize();

    var index = (this || _global).cells.indexOf(cell);

    this.cellChange(index);
  };
  /**
   * logic any time a cell is changed: added, removed, or size changed
   * @param {Integer} changedCellIndex - index of the changed cell, optional
   * @param {Boolean} isPositioningSlider - Positions slider after selection
   */


  proto.cellChange = function (changedCellIndex, isPositioningSlider) {
    var prevSelectedElem = (this || _global).selectedElement;

    this._positionCells(changedCellIndex);

    this._getWrapShiftCells();

    this.setGallerySize(); // update selectedIndex
    // try to maintain position & select previous selected element

    var cell = this.getCell(prevSelectedElem);

    if (cell) {
      (this || _global).selectedIndex = this.getCellSlideIndex(cell);
    }

    (this || _global).selectedIndex = Math.min((this || _global).slides.length - 1, (this || _global).selectedIndex);
    this.emitEvent("cellChange", [changedCellIndex]); // position slider

    this.select((this || _global).selectedIndex); // do not position slider after lazy load

    if (isPositioningSlider) {
      this.positionSliderAtSelected();
    }
  }; // -----  ----- //


  return Flickity;
});

export default exports;