import _flickity from "./flickity";
import _unidragger from "unidragger";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// drag
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _flickity, _unidragger, _fizzyUiUtils);
  } else {
    // browser global
    window.Flickity = factory(window, window.Flickity, window.Unidragger, window.fizzyUIUtils);
  }
})(window, function factory(window, Flickity, Unidragger, utils) {
  'use strict'; // ----- defaults ----- //

  utils.extend(Flickity.defaults, {
    draggable: ">1",
    dragThreshold: 3
  }); // ----- create ----- //

  Flickity.createMethods.push("_createDrag"); // -------------------------- drag prototype -------------------------- //

  var proto = Flickity.prototype;
  utils.extend(proto, Unidragger.prototype);
  proto._touchActionValue = "pan-y"; // --------------------------  -------------------------- //

  var isTouch = ("createTouch" in document);
  var isTouchmoveScrollCanceled = false;

  proto._createDrag = function () {
    this.on("activate", (this || _global).onActivateDrag);
    this.on("uiChange", (this || _global)._uiChangeDrag);
    this.on("deactivate", (this || _global).onDeactivateDrag);
    this.on("cellChange", (this || _global).updateDraggable); // TODO updateDraggable on resize? if groupCells & slides change
    // HACK - add seemingly innocuous handler to fix iOS 10 scroll behavior
    // #457, RubaXa/Sortable#973

    if (isTouch && !isTouchmoveScrollCanceled) {
      window.addEventListener("touchmove", function () {});
      isTouchmoveScrollCanceled = true;
    }
  };

  proto.onActivateDrag = function () {
    (this || _global).handles = [(this || _global).viewport];
    this.bindHandles();
    this.updateDraggable();
  };

  proto.onDeactivateDrag = function () {
    this.unbindHandles();

    (this || _global).element.classList.remove("is-draggable");
  };

  proto.updateDraggable = function () {
    // disable dragging if less than 2 slides. #278
    if ((this || _global).options.draggable == ">1") {
      (this || _global).isDraggable = (this || _global).slides.length > 1;
    } else {
      (this || _global).isDraggable = (this || _global).options.draggable;
    }

    if ((this || _global).isDraggable) {
      (this || _global).element.classList.add("is-draggable");
    } else {
      (this || _global).element.classList.remove("is-draggable");
    }
  }; // backwards compatibility


  proto.bindDrag = function () {
    (this || _global).options.draggable = true;
    this.updateDraggable();
  };

  proto.unbindDrag = function () {
    (this || _global).options.draggable = false;
    this.updateDraggable();
  };

  proto._uiChangeDrag = function () {
    delete (this || _global).isFreeScrolling;
  }; // -------------------------- pointer events -------------------------- //


  proto.pointerDown = function (event, pointer) {
    if (!(this || _global).isDraggable) {
      this._pointerDownDefault(event, pointer);

      return;
    }

    var isOkay = this.okayPointerDown(event);

    if (!isOkay) {
      return;
    }

    this._pointerDownPreventDefault(event);

    this.pointerDownFocus(event); // blur

    if (document.activeElement != (this || _global).element) {
      // do not blur if already focused
      this.pointerDownBlur();
    } // stop if it was moving


    (this || _global).dragX = (this || _global).x;

    (this || _global).viewport.classList.add("is-pointer-down"); // track scrolling


    (this || _global).pointerDownScroll = getScrollPosition();
    window.addEventListener("scroll", this || _global);

    this._pointerDownDefault(event, pointer);
  }; // default pointerDown logic, used for staticClick


  proto._pointerDownDefault = function (event, pointer) {
    // track start event position
    // Safari 9 overrides pageX and pageY. These values needs to be copied. #779
    (this || _global).pointerDownPointer = {
      pageX: pointer.pageX,
      pageY: pointer.pageY
    }; // bind move and end events

    this._bindPostStartEvents(event);

    this.dispatchEvent("pointerDown", event, [pointer]);
  };

  var focusNodes = {
    INPUT: true,
    TEXTAREA: true,
    SELECT: true
  };

  proto.pointerDownFocus = function (event) {
    var isFocusNode = focusNodes[event.target.nodeName];

    if (!isFocusNode) {
      this.focus();
    }
  };

  proto._pointerDownPreventDefault = function (event) {
    var isTouchStart = event.type == "touchstart";
    var isTouchPointer = event.pointerType == "touch";
    var isFocusNode = focusNodes[event.target.nodeName];

    if (!isTouchStart && !isTouchPointer && !isFocusNode) {
      event.preventDefault();
    }
  }; // ----- move ----- //


  proto.hasDragStarted = function (moveVector) {
    return Math.abs(moveVector.x) > (this || _global).options.dragThreshold;
  }; // ----- up ----- //


  proto.pointerUp = function (event, pointer) {
    delete (this || _global).isTouchScrolling;

    (this || _global).viewport.classList.remove("is-pointer-down");

    this.dispatchEvent("pointerUp", event, [pointer]);

    this._dragPointerUp(event, pointer);
  };

  proto.pointerDone = function () {
    window.removeEventListener("scroll", this || _global);
    delete (this || _global).pointerDownScroll;
  }; // -------------------------- dragging -------------------------- //


  proto.dragStart = function (event, pointer) {
    if (!(this || _global).isDraggable) {
      return;
    }

    (this || _global).dragStartPosition = (this || _global).x;
    this.startAnimation();
    window.removeEventListener("scroll", this || _global);
    this.dispatchEvent("dragStart", event, [pointer]);
  };

  proto.pointerMove = function (event, pointer) {
    var moveVector = this._dragPointerMove(event, pointer);

    this.dispatchEvent("pointerMove", event, [pointer, moveVector]);

    this._dragMove(event, pointer, moveVector);
  };

  proto.dragMove = function (event, pointer, moveVector) {
    if (!(this || _global).isDraggable) {
      return;
    }

    event.preventDefault();
    (this || _global).previousDragX = (this || _global).dragX; // reverse if right-to-left

    var direction = (this || _global).options.rightToLeft ? -1 : 1;

    if ((this || _global).options.wrapAround) {
      // wrap around move. #589
      moveVector.x %= (this || _global).slideableWidth;
    }

    var dragX = (this || _global).dragStartPosition + moveVector.x * direction;

    if (!(this || _global).options.wrapAround && (this || _global).slides.length) {
      // slow drag
      var originBound = Math.max(-(this || _global).slides[0].target, (this || _global).dragStartPosition);
      dragX = dragX > originBound ? (dragX + originBound) * 0.5 : dragX;
      var endBound = Math.min(-this.getLastSlide().target, (this || _global).dragStartPosition);
      dragX = dragX < endBound ? (dragX + endBound) * 0.5 : dragX;
    }

    (this || _global).dragX = dragX;
    (this || _global).dragMoveTime = new Date();
    this.dispatchEvent("dragMove", event, [pointer, moveVector]);
  };

  proto.dragEnd = function (event, pointer) {
    if (!(this || _global).isDraggable) {
      return;
    }

    if ((this || _global).options.freeScroll) {
      (this || _global).isFreeScrolling = true;
    } // set selectedIndex based on where flick will end up


    var index = this.dragEndRestingSelect();

    if ((this || _global).options.freeScroll && !(this || _global).options.wrapAround) {
      // if free-scroll & not wrap around
      // do not free-scroll if going outside of bounding slides
      // so bounding slides can attract slider, and keep it in bounds
      var restingX = this.getRestingPosition();
      (this || _global).isFreeScrolling = -restingX > (this || _global).slides[0].target && -restingX < this.getLastSlide().target;
    } else if (!(this || _global).options.freeScroll && index == (this || _global).selectedIndex) {
      // boost selection if selected index has not changed
      index += this.dragEndBoostSelect();
    }

    delete (this || _global).previousDragX; // apply selection
    // TODO refactor this, selecting here feels weird
    // HACK, set flag so dragging stays in correct direction

    (this || _global).isDragSelect = (this || _global).options.wrapAround;
    this.select(index);
    delete (this || _global).isDragSelect;
    this.dispatchEvent("dragEnd", event, [pointer]);
  };

  proto.dragEndRestingSelect = function () {
    var restingX = this.getRestingPosition(); // how far away from selected slide

    var distance = Math.abs(this.getSlideDistance(-restingX, (this || _global).selectedIndex)); // get closet resting going up and going down

    var positiveResting = this._getClosestResting(restingX, distance, 1);

    var negativeResting = this._getClosestResting(restingX, distance, -1); // use closer resting for wrap-around


    var index = positiveResting.distance < negativeResting.distance ? positiveResting.index : negativeResting.index;
    return index;
  };
  /**
   * given resting X and distance to selected cell
   * get the distance and index of the closest cell
   * @param {Number} restingX - estimated post-flick resting position
   * @param {Number} distance - distance to selected cell
   * @param {Integer} increment - +1 or -1, going up or down
   * @returns {Object} - { distance: {Number}, index: {Integer} }
   */


  proto._getClosestResting = function (restingX, distance, increment) {
    var index = (this || _global).selectedIndex;
    var minDistance = Infinity;
    var condition = (this || _global).options.contain && !(this || _global).options.wrapAround ? // if contain, keep going if distance is equal to minDistance
    function (dist, minDist) {
      return dist <= minDist;
    } : function (dist, minDist) {
      return dist < minDist;
    };

    while (condition(distance, minDistance)) {
      // measure distance to next cell
      index += increment;
      minDistance = distance;
      distance = this.getSlideDistance(-restingX, index);

      if (distance === null) {
        break;
      }

      distance = Math.abs(distance);
    }

    return {
      distance: minDistance,
      // selected was previous index
      index: index - increment
    };
  };
  /**
   * measure distance between x and a slide target
   * @param {Number} x - horizontal position
   * @param {Integer} index - slide index
   * @returns {Number} - slide distance
   */


  proto.getSlideDistance = function (x, index) {
    var len = (this || _global).slides.length; // wrap around if at least 2 slides

    var isWrapAround = (this || _global).options.wrapAround && len > 1;
    var slideIndex = isWrapAround ? utils.modulo(index, len) : index;
    var slide = (this || _global).slides[slideIndex];

    if (!slide) {
      return null;
    } // add distance for wrap-around slides


    var wrap = isWrapAround ? (this || _global).slideableWidth * Math.floor(index / len) : 0;
    return x - (slide.target + wrap);
  };

  proto.dragEndBoostSelect = function () {
    // do not boost if no previousDragX or dragMoveTime
    if ((this || _global).previousDragX === undefined || !(this || _global).dragMoveTime || // or if drag was held for 100 ms
    new Date() - (this || _global).dragMoveTime > 100) {
      return 0;
    }

    var distance = this.getSlideDistance(-(this || _global).dragX, (this || _global).selectedIndex);
    var delta = (this || _global).previousDragX - (this || _global).dragX;

    if (distance > 0 && delta > 0) {
      // boost to next if moving towards the right, and positive velocity
      return 1;
    } else if (distance < 0 && delta < 0) {
      // boost to previous if moving towards the left, and negative velocity
      return -1;
    }

    return 0;
  }; // ----- staticClick ----- //


  proto.staticClick = function (event, pointer) {
    // get clickedCell, if cell was clicked
    var clickedCell = this.getParentCell(event.target);
    var cellElem = clickedCell && clickedCell.element;

    var cellIndex = clickedCell && (this || _global).cells.indexOf(clickedCell);

    this.dispatchEvent("staticClick", event, [pointer, cellElem, cellIndex]);
  }; // ----- scroll ----- //


  proto.onscroll = function () {
    var scroll = getScrollPosition();
    var scrollMoveX = (this || _global).pointerDownScroll.x - scroll.x;
    var scrollMoveY = (this || _global).pointerDownScroll.y - scroll.y; // cancel click/tap if scroll is too much

    if (Math.abs(scrollMoveX) > 3 || Math.abs(scrollMoveY) > 3) {
      this._pointerDone();
    }
  }; // ----- utils ----- //


  function getScrollPosition() {
    return {
      x: window.pageXOffset,
      y: window.pageYOffset
    };
  } // -----  ----- //


  return Flickity;
});

export default exports;