import _getSize from "get-size";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// Flickity.Cell
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _getSize);
  } else {
    // browser global
    window.Flickity = window.Flickity || {};
    window.Flickity.Cell = factory(window, window.getSize);
  }
})(window, function factory(window, getSize) {
  'use strict';

  function Cell(elem, parent) {
    (this || _global).element = elem;
    (this || _global).parent = parent;
    this.create();
  }

  var proto = Cell.prototype;

  proto.create = function () {
    (this || _global).element.style.position = "absolute";

    (this || _global).element.setAttribute("aria-hidden", "true");

    (this || _global).x = 0;
    (this || _global).shift = 0;
  };

  proto.destroy = function () {
    // reset style
    this.unselect();
    (this || _global).element.style.position = "";
    var side = (this || _global).parent.originSide;
    (this || _global).element.style[side] = "";

    (this || _global).element.removeAttribute("aria-hidden");
  };

  proto.getSize = function () {
    (this || _global).size = getSize((this || _global).element);
  };

  proto.setPosition = function (x) {
    (this || _global).x = x;
    this.updateTarget();
    this.renderPosition(x);
  }; // setDefaultTarget v1 method, backwards compatibility, remove in v3


  proto.updateTarget = proto.setDefaultTarget = function () {
    var marginProperty = (this || _global).parent.originSide == "left" ? "marginLeft" : "marginRight";
    (this || _global).target = (this || _global).x + (this || _global).size[marginProperty] + (this || _global).size.width * (this || _global).parent.cellAlign;
  };

  proto.renderPosition = function (x) {
    // render position of cell with in slider
    var side = (this || _global).parent.originSide;
    (this || _global).element.style[side] = (this || _global).parent.getPositionValue(x);
  };

  proto.select = function () {
    (this || _global).element.classList.add("is-selected");

    (this || _global).element.removeAttribute("aria-hidden");
  };

  proto.unselect = function () {
    (this || _global).element.classList.remove("is-selected");

    (this || _global).element.setAttribute("aria-hidden", "true");
  };
  /**
   * @param {Integer} shift - 0, 1, or -1
   */


  proto.wrapShift = function (shift) {
    (this || _global).shift = shift;
    this.renderPosition((this || _global).x + (this || _global).parent.slideableWidth * shift);
  };

  proto.remove = function () {
    (this || _global).element.parentNode.removeChild((this || _global).element);
  };

  return Cell;
});

export default exports;