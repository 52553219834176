import _flickity from "./flickity";
import _unipointer from "unipointer";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// page dots
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _flickity, _unipointer, _fizzyUiUtils);
  } else {
    // browser global
    factory(window, window.Flickity, window.Unipointer, window.fizzyUIUtils);
  }
})(window, function factory(window, Flickity, Unipointer, utils) {
  // -------------------------- PageDots -------------------------- //
  'use strict';

  function PageDots(parent) {
    (this || _global).parent = parent;

    this._create();
  }

  PageDots.prototype = Object.create(Unipointer.prototype);

  PageDots.prototype._create = function () {
    // create holder element
    (this || _global).holder = document.createElement("ol");
    (this || _global).holder.className = "flickity-page-dots"; // create dots, array of elements

    (this || _global).dots = []; // events

    (this || _global).handleClick = (this || _global).onClick.bind(this || _global);
    this.on("pointerDown", (this || _global).parent.childUIPointerDown.bind((this || _global).parent));
  };

  PageDots.prototype.activate = function () {
    this.setDots();

    (this || _global).holder.addEventListener("click", (this || _global).handleClick);

    this.bindStartEvent((this || _global).holder); // add to DOM

    (this || _global).parent.element.appendChild((this || _global).holder);
  };

  PageDots.prototype.deactivate = function () {
    (this || _global).holder.removeEventListener("click", (this || _global).handleClick);

    this.unbindStartEvent((this || _global).holder); // remove from DOM

    (this || _global).parent.element.removeChild((this || _global).holder);
  };

  PageDots.prototype.setDots = function () {
    // get difference between number of slides and number of dots
    var delta = (this || _global).parent.slides.length - (this || _global).dots.length;

    if (delta > 0) {
      this.addDots(delta);
    } else if (delta < 0) {
      this.removeDots(-delta);
    }
  };

  PageDots.prototype.addDots = function (count) {
    var fragment = document.createDocumentFragment();
    var newDots = [];
    var length = (this || _global).dots.length;
    var max = length + count;

    for (var i = length; i < max; i++) {
      var dot = document.createElement("li");
      dot.className = "dot";
      dot.setAttribute("aria-label", "Page dot " + (i + 1));
      fragment.appendChild(dot);
      newDots.push(dot);
    }

    (this || _global).holder.appendChild(fragment);

    (this || _global).dots = (this || _global).dots.concat(newDots);
  };

  PageDots.prototype.removeDots = function (count) {
    // remove from this.dots collection
    var removeDots = (this || _global).dots.splice((this || _global).dots.length - count, count); // remove from DOM


    removeDots.forEach(function (dot) {
      (this || _global).holder.removeChild(dot);
    }, this || _global);
  };

  PageDots.prototype.updateSelected = function () {
    // remove selected class on previous
    if ((this || _global).selectedDot) {
      (this || _global).selectedDot.className = "dot";

      (this || _global).selectedDot.removeAttribute("aria-current");
    } // don't proceed if no dots


    if (!(this || _global).dots.length) {
      return;
    }

    (this || _global).selectedDot = (this || _global).dots[(this || _global).parent.selectedIndex];
    (this || _global).selectedDot.className = "dot is-selected";

    (this || _global).selectedDot.setAttribute("aria-current", "step");
  };

  PageDots.prototype.onTap = // old method name, backwards-compatible
  PageDots.prototype.onClick = function (event) {
    var target = event.target; // only care about dot clicks

    if (target.nodeName != "LI") {
      return;
    }

    (this || _global).parent.uiChange();

    var index = (this || _global).dots.indexOf(target);

    (this || _global).parent.select(index);
  };

  PageDots.prototype.destroy = function () {
    this.deactivate();
    this.allOff();
  };

  Flickity.PageDots = PageDots; // -------------------------- Flickity -------------------------- //

  utils.extend(Flickity.defaults, {
    pageDots: true
  });
  Flickity.createMethods.push("_createPageDots");
  var proto = Flickity.prototype;

  proto._createPageDots = function () {
    if (!(this || _global).options.pageDots) {
      return;
    }

    (this || _global).pageDots = new PageDots(this || _global); // events

    this.on("activate", (this || _global).activatePageDots);
    this.on("select", (this || _global).updateSelectedPageDots);
    this.on("cellChange", (this || _global).updatePageDots);
    this.on("resize", (this || _global).updatePageDots);
    this.on("deactivate", (this || _global).deactivatePageDots);
  };

  proto.activatePageDots = function () {
    (this || _global).pageDots.activate();
  };

  proto.updateSelectedPageDots = function () {
    (this || _global).pageDots.updateSelected();
  };

  proto.updatePageDots = function () {
    (this || _global).pageDots.setDots();
  };

  proto.deactivatePageDots = function () {
    (this || _global).pageDots.deactivate();
  }; // -----  ----- //


  Flickity.PageDots = PageDots;
  return Flickity;
});

export default exports;